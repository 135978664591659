import Service from './baseService';

export const getTourRouteVehicleAssignments = (tourRouteID) => {
    return Service.get(`/tourRoutes/vehicleAssignments/${tourRouteID}`);
};

export const createTicketsService = (payload) => {
    return Service.post(`/tourRoutes/tickets/`, payload);
};

export const getTourTicketInfoSettingsService = (vehicleAssignmentID) => {
    return Service.get(`/tourRoutes/tourTicketInfoSettings`, { params: { vehicleAssignmentID } });
};
