export const getCompanyId = () => {
    let user = getCustomerDetails();
    return user['companyID'];
};

export const getEnterpriseId = () => {
    let url = window.location.href;
    const urlObject = new URL(url);
    const hostName = urlObject.hostname;
    let enterprise = '';
    let enterprises = [
        // Internal URLs
        { id: 21, hostname: 'localhost' },
        { id: 21, hostname: 'cp-opv3-qa.tbndrives.com' },
        { id: 4, hostname: 'customerportal.tbndrives.com' },
        { id: 21, hostname: 'customerportal.thebusnetwork.com' },
        { id: 2, hostname: 'dattcocustomerportal.thebusnetwork.com' },
        { id: 1, hostname: 'opv2customerportal.thebusnetwork.com' },
        { id: 21, hostname: 'opv3.tbndrives.com' },
        { id: 58, hostname: 'qualityassuranceportalprod.tbndrives.com' },
        { id: 4, hostname: 'samplestagelinesportal.tbndrives.com' },
        { id: 21, hostname: 'tbn-customer-portal-dev.web.app' },
        { id: 4, hostname: 'tbn-customer-portal-qa.web.app' },
        { id: 4, hostname: 'tbn-customer-portal-staging.web.app' },

        // Customer URLs
        { id: 54, hostname: 'abaportal.tbndrives.com' },
        { id: 40, hostname: 'affordablelimogrportal.tbndrives.com' },
        { id: 88, hostname: 'agapeportal.tbndrives.com' },
        { id: 74, hostname: 'agoffportal.tbndrives.com' },
        { id: 60, hostname: 'allaroundportal.tbndrives.com' },
        { id: 53, hostname: 'americancharterlinesportal.tbndrives.com' },
        { id: 63, hostname: 'americanstarportal.tbndrives.com' },
        { id: 122, hostname: 'anchorportal.tbndrives.com' },
        { id: 17, hostname: 'andersonportal.tbndrives.com' },
        { id: 17, hostname: 'customer.goanderson.com' },
        { id: 6, hostname: 'annettportal.tbndrives.com' },
        { id: 29, hostname: 'astroportal.tbndrives.com' },
        { id: 83, hostname: 'baronsportal.tbndrives.com' },
        { id: 30, hostname: 'besttoursportal.tbndrives.com' },
        { id: 27, hostname: 'bloomportal.tbndrives.com' },
        { id: 80, hostname: 'bluelakesportal.tbndrives.com' },
        { id: 90, hostname: 'bluestarportal.tbndrives.com' },
        { id: 48, hostname: 'brtcharterportal.tbndrives.com' },
        { id: 71, hostname: 'campbellportal.tbndrives.com' },
        { id: 39, hostname: 'christianbrosportal.tbndrives.com' },
        { id: 55, hostname: 'clinetoursportal.tbndrives.com' },
        { id: 81, hostname: 'cowtownportal.tbndrives.com' },
        { id: 86, hostname: 'crosscountryportal.tbndrives.com' },
        { id: 85, hostname: 'croswellportal.tbndrives.com' },
        { id: 66, hostname: 'customcoachportal.tbndrives.com' },
        { id: 3, hostname: 'dandipertportal.tbndrives.com' },
        { id: 2, hostname: 'dattcoportal.tbndrives.com' },
        { id: 2, hostname: 'customers.dattco.com' },
        { id: 42, hostname: 'davidtoursportal.tbndrives.com' },
        { id: 75, hostname: 'dctrailsportal.tbndrives.com' },
        { id: 64, hostname: 'decampportal.tbndrives.com' },
        { id: 72, hostname: 'dfportal.tbndrives.com' },
        { id: 36, hostname: 'ebmeyerportal.tbndrives.com' },
        { id: 41, hostname: 'elitecoachportal.tbndrives.com' },
        { id: 56, hostname: 'empirecoachlineportal.tbndrives.com' },
        { id: 70, hostname: 'firstclassportal.tbndrives.com' },
        { id: 76, hostname: 'freeenterpriseportal.tbndrives.com' },
        { id: 73, hostname: 'fullingtonportal.tbndrives.com' },
        { id: 24, hostname: 'gonectportal.tbndrives.com' },
        { id: 7, hostname: 'graylinetnportal.tbndrives.com' },
        { id: 77, hostname: 'groundtravelportal.tbndrives.com' },
        { id: 61, hostname: 'haleportal.tbndrives.com' },
        { id: 43, hostname: 'hamptonjitneyportal.tbndrives.com' },
        { id: 22, hostname: 'hlcharterportal.tbndrives.com' },
        { id: 44, hostname: 'jamesriverportal.tbndrives.com' },
        { id: 87, hostname: 'journeyportal.tbndrives.com' },
        { id: 38, hostname: 'king-grayportal.tbndrives.com' },
        { id: 19, hostname: 'kissalaskaportal.tbndrives.com' },
        { id: 8, hostname: 'krapfportal.tbndrives.com' },
        { id: 9, hostname: 'lamersportal.tbndrives.com' },
        { id: 10, hostname: 'lamoilleportal.tbndrives.com' },
        { id: 11, hostname: 'leprechaunlinesportal.tbndrives.com' },
        { id: 49, hostname: 'littlerockportal.tbndrives.com' },
        { id: 12, hostname: 'lorenzportal.tbndrives.com' },
        { id: 33, hostname: 'mayoportal.tbndrives.com' },
        { id: 123, hostname: 'monseyportal.tbndrives.com' },
        { id: 13, hostname: 'mtrwesternportal.tbndrives.com' },
        { id: 82, hostname: 'nationaltrailsportal.tbndrives.com' },
        { id: 89, hostname: 'newmexicotexasportal.tbndrives.com' },
        { id: 67, hostname: 'niagaraportal.tbndrives.com' },
        { id: 57, hostname: 'northfieldportal.tbndrives.com' },
        { id: 14, hostname: 'nwnavigatorportal.tbndrives.com' },
        { id: 45, hostname: 'pacificcrestportal.tbndrives.com' },
        { id: 26, hostname: 'panoramaportal.tbndrives.com' },
        { id: 32, hostname: 'polyadportal.tbndrives.com' },
        { id: 68, hostname: 'redstarportal.tbndrives.com' },
        { id: 15, hostname: 'royalportal.tbndrives.com' },
        { id: 79, hostname: 'shafersportal.tbndrives.com' },
        { id: 69, hostname: 'signatureportal.tbndrives.com' },
        { id: 5, hostname: 'starlineportal.tbndrives.com' },
        { id: 16, hostname: 'starrtoursportal.tbndrives.com' },
        { id: 18, hostname: 'swarthoutportal.tbndrives.com' },
        { id: 78, hostname: 'transportationservicesportal.tbndrives.com' },
        { id: 50, hostname: 'trobecsportal.tbndrives.com' },
        { id: 84, hostname: 'uniquelimoportal.tbndrives.com' },
        { id: 52, hostname: 'vandaliaportal.tbndrives.com' },
        { id: 47, hostname: 'venturebustoursportal.tbndrives.com' },
        { id: 35, hostname: 'voigtportal.tbndrives.com' },
        { id: 65, hostname: 'waymakerportal.tbndrives.com' },
        { id: 34, hostname: 'wernercoachportal.tbndrives.com' },
        { id: 59, hostname: 'westernportal.tbndrives.com' },
        { id: 25, hostname: 'westpointportal.tbndrives.com' },
        { id: 37, hostname: 'windstarportal.tbndrives.com' },
        { id: 46, hostname: 'youngportal.tbndrives.com' },
    ];
    enterprises.forEach((c) => {
        if (hostName === c.hostname) {
            enterprise = c.id;
        }
    });
    return enterprise;
};

export const getCustomerDetails = () => {
    return JSON.parse(localStorage.getItem('user'));
};
